import React, { FunctionComponent } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Section from '../shared/section/sectionWrapper'
import Text from '../shared/text'
import { StaticImage } from 'gatsby-plugin-image'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
const Career: FunctionComponent = () => {

    return (
        //   <Section className="bg-bytestrone-blue px-6 ">
        <div className="bg-bytestrone-blue md:p-10 sm:p-5 ">
            <div id="pragmatic-solution" className="flex flex-col  w-full h-auto p-5 md:py-10 py-20 space-y-5 ">
                <Text className="text-center text-white lg:text-right whitespace-nowrap" textType="headerL">Careers</Text>

                <div className="flex flex-wrap h-auto content-center  justify-center items-center md:pl-11 pt-10 space-y-14">

                    <div className="lg">
                        <div className="lg:px-8">
                            <Text textType="contentXXl" className="text-white">
                                <strong> Bytestrone</strong> handholds your enthusiasm to reach the extraordinary. We provide an environment where you can have your own space to grow and express.   <strong>Join us </strong>to grow together!!!
                            </Text>

                        </div>
                    </div>
                    <div className="bg-bytestrone-red rounded-full whitespace-nowrap text-center  p-2 mt-4 w-auto  text-base"> <AniLink className="font-bytestrone text-white" to="/careers/find-open-positions">
                        FIND OPEN POSITIONS
                    </AniLink></div>
                </div>
            </div>
        </div>

    )

}


export default Career;