import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React, { FunctionComponent } from "react";
import Section from "../shared/section/sectionWrapper";
import Text from "../shared/text";
import { graphql, useStaticQuery } from "gatsby";
import { useSnapCarousel} from 'react-snap-carousel';
const peopleVoicesQuery = graphql`
      query peopleVoicesQuery {
        allStrapiPeoplevoices(sort: {order: ASC, fields: sequence}) {
          edges {
            node {
              id
              fullname
              description
              designation
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      blurredOptions: {width: 400}
                      placeholder: BLURRED
                      
                    )
                  }
                }
                
              }
            }
          }
        }
      }`
const WhyBytestrone: FunctionComponent = () => {
  const { allStrapiPeoplevoices } = useStaticQuery(peopleVoicesQuery);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1500, min: 768 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 940, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const renderCarosel = () => {
    const scrollPadding = 15;
    const { scrollRef, pages, activePageIndex, next, prev, goTo } =
      useSnapCarousel();



    

    return (
      <>
        <div
          ref={scrollRef}
          style={{
            display: 'flex',
            overflow: 'hidden',
            scrollSnapType: 'x mandatory',
            scrollPadding: `${scrollPadding}px`
          }}
        >
          {

            allStrapiPeoplevoices.edges.map((item, index) => {
              // const length = allStrapiPeoplevoices.edges.length;
              setTimeout(() =>
                autoPlay()
                , 40000);

              const autoPlay = () => {
                {

                  pages.map((item, index) => {
                    if (activePageIndex === index) {
                      goTo(0)
                    } else {
                      next()
                    }
                  })
                }

              }
              const image = getImage(item.node.image.localFile.childImageSharp.gatsbyImageData);

              return (
                <div key={index} style={{
                  fontSize: '50px',
                  height: 'auto',
                  flexShrink: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }} className="w-full md:w-full lg:w-4/12">
                  <div className="flex flex-col relative h-full    md:max-w-lg  rounded-lg bg-white border-2 shadow-lg mx-5 ">

                    <div className=" pt-6 pl-5 flex  self-center">
                      <div id="people-voices-profile" className="justify-center" style={{ height: "100px", width: "100px" }}>
                        {image ? <GatsbyImage image={image} alt={item.node.fullname} className="border-none transform " /> : null}
                      </div>
                    </div>
                    <div className="flex flex-col space-y-0 md:space-y-0  xl:space-y-0">

                      <div className="px-5  flex  min-h-max h-min-48 md:h-56   lg:h-72 xl:h-min-56  ">
                        <Text
                          className="pt-7 text-left" textType="contentSm" >
                          <strong>&ldquo;</strong>
                          {item.node.description}<strong>&rdquo;</strong>
                        </Text>
                      </div>
                      <div className="px-5 pt-5 flex  "  >
                        <Text
                          className="  text-bytestrone-blue w-full text-right font-bold"
                          textType="contentL"
                        >
                          {item.node.fullname}
                          <br />
                          <span style={{ fontSize: '12px' }}>({item.node.designation})</span>
                        </Text>
                      </div>
                    </div>
                  </div>

                </div>


              )
            })}

        </div>
        <div style={{
          margin: 'auto',
          display:'flex'
        }}>
          <button className='bg-bytestrone-blue rounded-full ' style={{
            // display: 'flex',
            margin: '1rem',
            padding: '2px',
            width: '30px',
            height:'30px',
            borderRadius: '50%',
            display: 'inline-block',
            opacity:'.65',
          }} onClick={() => prev()}><span style={{
            color: "white",
          }} > &laquo;</span></button>
          <span style={{
            margin: '1rem',
            paddingTop: '8px',
          }}>{pages.map((_, indexpage) => (<button key={indexpage} style={indexpage === activePageIndex ? { opacity: 0.5 } : {}}
            onClick={() => goTo(indexpage)}><span style={{
              height: '12px',
              width: '12px',
              borderRadius: '50%',
              backgroundColor: '#bbb',
              justifyContent:'center',
              margin: '2px',
              display: 'inline-block'
            }} ></span></button>))}</span>
          <button className='bg-bytestrone-blue rounded-full ' style={{
            margin: '1rem',
            padding: '2px',
            width: '30px',
            height:'30px',
            borderRadius: '50%',
            display: 'inline-block',
            opacity:'.65'

          }} onClick={() => next()}><span style={{
            color: "white",
          }}>&raquo;</span></button>
        </div>
      </>
    );
  };
  return (

    <Section className="bg-white px-4">
      {/* <div
          id="hidden-challenges"
          className="flex flex-col w-full h-full p-2 py-8 space-y-5"
        >
          <Text
            className="text-center text-bytestrone-blue lg:text-left"
            textType="headerL"
          >
            Why Bytestrone?
          </Text>
        </div> */}

      {/* <div className="flex flex-col w-full h-full p-2 py-8 space-y-5">
          <p className="text-center text-bytestrone-green lg:text-center text-4xl font-bold">Life at Bytestrone</p>
          <p className="text-center text-bytestrone-green lg:text-center text-1xl font-bold">An open,positive mindset</p>

          <div className="flex flex-col w-1/2 h-full p-2 py-8 space-y-5 ">

          </div>
        </div>

        <div className="flex flex-col w-full h-full p-2 py-8 space-y-5">
          <p className="text-center text-bytestrone-green lg:text-center text-4xl font-bold">Culture</p>

          <div className="">
          </div>
        </div>

        <div className="flex flex-col w-full h-full p-2 py-8 space-y-5">
          <p className="text-center text-bytestrone-green lg:text-center text-4xl font-bold">Benefits of joining us</p>

          <div className="">
          </div>
        </div> */}

      <div className="flex flex-col w-full  p-2 py-8 space-y-5 h-full">
        <Text
          className=" capitalize text-center text-bytestrone-blue lg:text-left font-bold "
          textType="headerL"
        >
          People voices
        </Text>
        <div id="peopleVoicesContent" className="flex flex-col md:ml-22 md:mr-22 " >
          {renderCarosel()}
        </div>
      </div>
    </Section>
  );
};

export default WhyBytestrone;
