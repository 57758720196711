import React,{FunctionComponent} from "react";
import Career from "../../components/careers/career";
import EPod from "../../components/careers/e-pod";
import OurValues from "../../components/careers/our-values";
import WhyBytestrone from "../../components/careers/why-bytestrone-careers";

const Careers:FunctionComponent = ()=> {
    return(<>
    <Career></Career>
    <WhyBytestrone></WhyBytestrone>
    {/* <EPod></EPod> */}
    {/* <OurValues></OurValues> */}
    </>)
}

export default Careers;